import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { getCurrentOrDefaultPeriod, publishedPeriodFormatter, processChatParams } from '../../utils/core';
import { getUserPyramid } from './user';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notifications: null,
  screens: null,
  editSelectedScreen: null,
  selectedItem: null,
  activeScreen: null,
  screenFormOpen: false,
  itemFormOpen: false,
  publishedPeriods: null
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setScreens(state, action) {
      state.isLoading = false;
      state.screens = action.payload;
    },

    setActiveScreen(state, action) {
      state.isLoading = false;
      state.activeScreen = action.payload;
    },

    setPublishedPeriods(state, action) {
      // state.isLoading = false;
      state.publishedPeriods = action.payload;
    },

    setScreenFormOpen(state, action) {
      state.screenFormOpen = action.payload;
    },

    setItemFormOpen(state, action) {
      state.itemFormOpen = action.payload;
    },

    setEditSelectedScreen(state, action) {
      state.editSelectedScreen = action.payload;
    },

    setEditSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },

    notificationSuccess(state, action) {
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setActiveScreen,
  setPublishedPeriods,
  setEditSelectedScreen,
  setEditSelectedItem,
  setScreenFormOpen,
  setItemFormOpen,
  setScreens
} = slice.actions;

// ----------------------------------------------------------------------

export function getDashboardScreens() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const periods = await axios.get(`/api/data/periods`);
      if (periods !== null) {
        const formattedResp = periods.data.map(publishedPeriodFormatter);
        const response = await axios.get('/api/dashboards');
        if (response !== null && response.data !== undefined) {
          dispatch(getUserPyramid({ entities: response.data[0].items[0].entity_id }));
        }
        const patchedScreens = response.data.map((screen) => ({
          ...screen,
          items: screen.items.map((item) => ({
            ...item,
            dataLabels: !!['donut', 'pie', 'stackedBar', 'verticalStackedBar'].includes(item.chart_type),
            period: getCurrentOrDefaultPeriod(
              formattedResp.filter((resp) => resp.kind === item.kind),
              item.period_slider_type,
              {}
            )
          }))
        }));
        dispatch(slice.actions.setScreens(patchedScreens));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublishedPeriods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data/periods`);
      const formattedResp = response.data.map(publishedPeriodFormatter);
      dispatch(slice.actions.setPublishedPeriods(formattedResp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateScreen(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/dashboards', { dashboard: params });
      } else {
        await axios.put(`/api/dashboards/${params.id}`, { dashboard: params });
      }
      dispatch(getDashboardScreens());
      dispatch(setScreenFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateScreenItem(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response = null;
      if (params.id === undefined || params.id === '') {
        response = await axios.post('/api/dashboards/item', { dashboard_item: params });
      } else {
        response = await axios.put(`/api/dashboards/item/${params.id}`, { dashboard_item: params });
      }
      if (response !== null && response.data !== undefined) {
        dispatch(patchScreenItem(response.data));
        dispatch(setItemFormOpen(false));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function patchScreenItem(newScreens) {
  return (dispatch, getState) => {
    const { screens, publishedPeriods } = getState().dashboard;
    const { userPyramid } = getState().user;
    const existingScreens = { ...screens };

    const patchedScreens = newScreens.map((screen, screenId) => ({
      ...screen,
      items: screen.items.map((item) => {
        const existingItem = existingScreens[screenId].items.find((exItem) => exItem.id === item.id);
        return {
          ...item,
          isLoading: existingItem !== undefined && existingItem.isLoading !== undefined ? existingItem.isLoading : true,
          data: existingItem !== undefined && existingItem.data !== undefined ? existingItem.data : [],
          period:
            existingItem !== undefined && existingItem.period !== undefined
              ? existingItem.period
              : getCurrentOrDefaultPeriod(
                  publishedPeriods.filter((resp) => resp.kind === item.kind),
                  item.period_slider_type,
                  {}
                ),
          touched: existingItem !== undefined && existingItem.touched !== undefined ? existingItem.touched : true, // for immediate launch of the default tab generic blocks
          pyramid:
            existingItem !== undefined && existingItem.pyramid !== undefined
              ? existingItem.pyramid
              : {
                  tree: [{ ...userPyramid.tree[0], checked: true }],
                  selected: item.entity_id
                }
        };
      })
    }));
    dispatch(setScreens(patchedScreens));
  };
}

export function updateScreenItem(updates) {
  return (dispatch, getState) => {
    const { screens } = getState().dashboard;
    let updatedScreens = { ...screens };
    updates.forEach((update) => {
      const itemIndex = updatedScreens[update.screenId].items.findIndex((item) => item.id === update.itemId);
      const updatedItem = { ...updatedScreens[update.screenId].items[itemIndex], [update.field]: update.value };
      updatedScreens = Object.assign([], updatedScreens, {
        [update.screenId]: {
          ...updatedScreens[update.screenId],
          items: Object.assign([], updatedScreens[update.screenId].items, { [itemIndex]: updatedItem })
        }
      });
    });
    dispatch(setScreens(updatedScreens));
  };
}

export function deleteScreenItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.delete(`/api/dashboards/item/${id}`);
    if (response !== null && response.data !== undefined) {
      dispatch(patchScreenItem(response.data));
    }
  };
}

export function notificationCheck() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/data/consistency');
      dispatch(slice.actions.notificationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function notificationFix(key) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/data/consistency', {
        params: { key }
      });
      dispatch(slice.actions.notificationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashBoardBlock(params) {
  return async (dispatch, getState) => {
    const { screens } = getState().dashboard;
    dispatch(
      updateScreenItem([{ value: true, field: 'isLoading', screenId: params.screenId, itemId: params.itemParams.id }])
    );

    try {
      const itemParams = screens
        .map((screen) => screen.items)
        .flat()
        .find((item) => item.id === params.itemParams.id);
      if (itemParams !== undefined) {
        // console.log('itemParams', itemParams);
        const p = processChatParams(itemParams);
        // console.log('itemParams p', p);
        if (
          p.entity_id !== undefined &&
          p.entity_id.length > 0 &&
          p.start_date !== undefined &&
          p.end_date !== undefined &&
          p.ids !== undefined
        ) {
          const response = await axios.post(`/api/data/chart`, { formula_value: p });

          dispatch(
            updateScreenItem([
              { value: response.data, field: 'data', screenId: params.screenId, itemId: params.itemParams.id },
              { value: false, field: 'isLoading', screenId: params.screenId, itemId: params.itemParams.id },
              { value: false, field: 'touched', screenId: params.screenId, itemId: params.itemParams.id }
            ])
          );
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        updateScreenItem([
          { value: false, field: 'isLoading', screenId: params.screenId, itemId: params.itemParams.id },
          { value: false, field: 'touched', screenId: params.screenId, itemId: params.itemParams.id }
        ])
      );
    }
  };
}

export function getDashBoardItem(params) {
  return async (dispatch) => {
    dispatch(
      updateScreenItem([{ value: true, field: 'isLoading', screenId: params.screenId, itemId: params.itemParams.id }])
    );
    try {
      const p = processChatParams(params.itemParams);

      if (
        p.entity_id !== undefined &&
        p.entity_id.length > 0 &&
        p.start_date !== undefined &&
        p.end_date !== undefined &&
        p.ids !== undefined
      ) {
        const response = await axios.post(`/api/data/chart`, { formula_value: p });
        return { screenId: params.screenId, itemId: params.itemParams.id, data: response.data };
      }
    } catch (error) {
      return error;
    }
    return undefined;
  };
}
